import {
  getFirestore,
  collection,
  getDocs,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

const GetDataFromFirestore = async (setData) => {
  const db = getFirestore();
  const promocodesCollection = collection(db, "promocodes");

  try {
    const querySnapshot = await getDocs(promocodesCollection);
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    setData(data);
  } catch (error) {
    console.error("Error getting data from Firestore:", error);
  }
};

const deleteDocument = async (
  documentId,
  setUpdate,
  imagePath,
  setImage,
  update
) => {
  const db = getFirestore();
  const storage = getStorage();
  const imageRef = ref(storage, imagePath);
  try {
    const docRef = doc(db, "promocodes", documentId);
    await deleteDoc(docRef);
    await deleteObject(imageRef)
      .then(() => {
        setImage(" ");
        return;
      })
      .catch((error) => {
        return;
      });
    setUpdate(!update);
  } catch (error) {
    console.error("Error deleting document: ", error);
  }
};
export { deleteDocument };
export default GetDataFromFirestore;
