import React, { useState } from "react";
import { Form, Input, Select, Row, Col } from "antd";
import { Button } from "react-bootstrap";
import ImageUploader from "./ImageUploader";
// import StoresCall from "../API-Call/StoresCall";
// import { FixArabic } from "../constant/FixArabic";
import { handelSignOut } from "../Firebase/firebase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Promocodes, { UploadImage } from "../API-Call/Promocodes";
import currentDate from "../constant/currentDate";
import TimeToEnd from "../constant/TimeToEnd";
import {
  ButtonSection,
  StyledForm,
  ImgaeSection,
  Header,
} from "../Style/styles";
import RemovePromocode from "./remove-promocode";
import PhoneAuth from "../Firebase-Auth/PhoneAuth";

const { Option } = Select;

const AddPromotion = ({ setShowPromCode }) => {
  const MySwal = withReactContent(Swal);
  const date = currentDate();
  const [form] = Form.useForm();
  const [voucherCode, setVoucherCode] = useState(null);
  const [selectedPromoType, setSelectedPromoType] = useState("");
  // const [stores, setStores] = useState([]);
  // const [storeId, setStoreId] = useState("");
  const [countryTrue, setCountryTrue] = useState(false);
  const [isCorrectCode, setIsCorrectCode] = useState(false);
  const [imageTrue, setImageTrue] = useState(false);
  const [image_En_Data, setImage_En_data] = useState("");
  const [image_Ar_Data, setImage_Ar_data] = useState("");
  const [download, setDownload] = useState("");
  const [nameAR, setNameAR] = useState("");
  const [nameEN, setNameEN] = useState("");
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState("");
  const [country, setCountry] = useState("");
  const [descriptionAR, setDescriptionAR] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [phone, setPhone] = useState("");
  const [promoPlace, setPromoPlace] = useState("");
  const [location, setLocation] = useState("");
  const [website, setWebsite] = useState(false);
  const [deletButtonClicked, setDeleteButton] = useState(false);
  const [presesTrue, setPresesTrue] = useState(false);
  const [dateTrue, setDateTrue] = useState(false);
  const [presesValue, setPresesValue] = useState("");

  const backPressed = () => {
    setShowPromCode(false);
  };

  // presses Numbers only
  const handlePressesKeyPress = (e) => {
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(e.key)) {
      e.preventDefault();
    }
  };
  const handlePressesInput = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setPresesValue(Number(numericValue));
  };

  // Phone Numbers only
  const handleKeyPress = (e) => {
    const isNumeric = /^[0-9+]$/;
    if (!isNumeric.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setPhone(numericValue);
  };

  // The Main object
  const promocodeObject = {
    id: "",
    nameEN: nameEN,
    nameAR: nameAR,
    image_en: "",
    imagePath_en: "",
    image_ar: "",
    imagePath_ar: "",
    country: country,
    phone: phone,
    codeTybe: selectedPromoType,
    storeId: "",
    descriptionAR: descriptionAR,
    descriptionEN: descriptionEN,
    code: voucherCode,
    promoPlace: promoPlace,
    location: location,
    presesValue: presesValue,
    website: website,
    download: download,
    startDate: startDate,
    endDate: endDate,
    remainingTime: 0,
  };

  // Handel Store Error  /  not working for now
  // useEffect(() => {
  //   setStores([]); // Need to be remove
  //   if (!stores) {
  //     MySwal.fire(<h3>خطاء في السيرفر</h3>);
  //   }
  // }, [stores, MySwal]);
  // async function getStores(country) {
  //   const storesData = await StoresCall(country);
  //   // setStores(storesData);
  // }

  // Country
  const handleCountryChange = (value) => {
    // getStores(value);
    setCountry(value);
    // setCountryTrue(true);
  };

  // The Image container show
  const handleImageChange = (value) => {
    if (value === "Enable") {
      setImageTrue(true);
    } else {
      setImageTrue(false);
    }
  };

  // The Name  EN  Imput
  const nameValueAR = (event) => {
    setNameAR(event.target.value);
  };

  // The Name  AR  Imput
  const nameValueEN = (event) => {
    setNameEN(event.target.value);
  };

  // The Store Imput
  // const handleStoreChange = (value) => {
  //   setStoreId(value);
  // };

  // The Description Arabic Imput
  const handelTypeValueAR = (event) => {
    setDescriptionAR(event.target.value);
  };

  // The Description English Imput
  const handelTypeValueEN = (event) => {
    setDescriptionEN(event.target.value);
  };

  // The Location Date Imput
  const handelLocationValue = (event) => {
    setLocation(event.target.value);
  };

  // The Website Date Imput
  const handelWebsiteValue = (event) => {
    setWebsite(event.target.value);
  };
  // The Download Date Imput
  const handelDownloadValue = (event) => {
    setDownload(event.target.value);
  };

  // The Start Date Imput
  const handelStartDateValue = (event) => {
    setStartDate(event.target.value);
  };

  // The End Date Imput
  const handelEndDateValue = (event) => {
    setEndDate(event.target.value);
  };

  // The Submit Button Founction Start -->
  const buttonCliked = async () => {
    const remainingDays = TimeToEnd(startDate, endDate);
    promocodeObject.remainingTime = remainingDays;

    if (
      nameAR === "" ||
      nameEN === "" ||
      country === "" ||
      promoPlace === "" ||
      // storeId === "" ||
      descriptionAR === "" ||
      descriptionEN === ""
    ) {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "red" }}>فشل التسجيل</h3>
            <p>تأكد من كل الخانات الإجبارية</p>
          </div>
        ),
        didOpen: () => {
          MySwal.showLoading();
        },
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
    } else {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "blue" }}>جاري التسجيل</h3>
          </div>
        ),

        didOpen: () => {
          MySwal.showLoading();
        },
      });
      if (image_En_Data !== "") {
        await UploadImage(image_En_Data, country).then(async (val) => {
          promocodeObject.image_en = val.url;
          promocodeObject.imagePath_en = val.path;
        });
      } else {
        promocodeObject.image_en = "undefined";
        promocodeObject.imagePath_en = "undefined";
      }
      if (image_Ar_Data !== "") {
        await UploadImage(image_Ar_Data, country).then(async (val) => {
          promocodeObject.image_ar = val.url;
          promocodeObject.imagePath_ar = val.path;
          await fireStoreCall(promocodeObject);
        });
      } else {
        promocodeObject.image_ar = "undefined";
        promocodeObject.imagePath_ar = "undefined";
        await fireStoreCall(promocodeObject);
      }
    }
  };

  // <-- The Submit Button Founction End

  // Saveing To Firestore Function Start -->
  async function fireStoreCall(promocodeObject) {
    await Promocodes(promocodeObject).then((val) => {
      setTimeout(() => {
        MySwal.close();
      }, 500);

      setTimeout(() => {
        if (val) {
          switch (val) {
            case 200:
              form.resetFields();
              setCountryTrue(false);
              setSelectedPromoType("");
              setImageTrue(false);
              setCountry("");
              setNameAR("");
              setNameEN("");
              setStartDate(date);
              setEndDate("");
              setDescriptionAR("");
              setDescriptionEN("");
              promocodeObject.image = "";
              setVoucherCode(null);
              setPhone("");
              setLocation("");
              setWebsite("");
              setPromoPlace("");
              setDownload("");
              return MySwal.fire(
                <div>
                  <h3 style={{ color: "green" }}>تم التسجيل</h3>
                </div>
              );
            case 404:
              return MySwal.fire(
                <div>
                  <h3 style={{ color: "red" }}>فشل التسجيل</h3>
                </div>
              );
            default:
              break;
          }
        }
      }, 800);
    });
  }
  // <-- Saveing To Firestore Function End

  return (
    <div>
      <Header>
        {isCorrectCode && (
          <Button
            onClick={() => {
              handelSignOut(setIsCorrectCode);
            }}
            variant="danger"
          >
            تسجيل خروج
          </Button>
          // backPressed
        )}
        {isCorrectCode && (
          <Button
            onClick={backPressed}
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              fontSize: 20,
              marginLeft: "1%",
            }}
          >
            القائمة الرائيسية
          </Button>
        )}
      </Header>
      <h1 style={{ margin: 20, color: "#f1fae3", fontWeight: "bold" }}>
        Promo Panle
      </h1>

      {!isCorrectCode ? (
        <PhoneAuth isCorrectCode={setIsCorrectCode} />
      ) : (
        <>
          {deletButtonClicked ? (
            <RemovePromocode setDeleteButton={setDeleteButton} />
          ) : (
            <StyledForm form={form} layout="vertical">
              <Form
                style={{
                  direction: "rtl",
                }}
                form={form}
                layout="vertical"
              >
                <Col span={12}>
                  <Form.Item
                    label={
                      <div>
                        <h3>البلد</h3>
                      </div>
                    }
                    name="Country"
                    rules={[
                      { required: true, message: "Please select Country!" },
                    ]}
                  >
                    <Select
                      placeholder="اختر البلد"
                      onChange={handleCountryChange}
                    >
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="eg"
                      >
                        مصر
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="sa"
                      >
                        المملكة السعودية
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="kw"
                      >
                        الكويت
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="ae"
                      >
                        الأمارات
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="qa"
                      >
                        قطر
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="bh"
                      >
                        البحرين
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="om"
                      >
                        عمان
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الأسم بالعربي</h3>
                        </div>
                      }
                      name="promoName-ar"
                      rules={[
                        {
                          required: true,
                          message: "من فضلك اخل اسم العرض بالعربي",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ fontSize: 17 }}
                        placeholder="اسم العرض"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        onChange={nameValueAR}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الأسم بالانجليزي</h3>
                        </div>
                      }
                      name="promoName-en"
                      rules={[
                        {
                          required: true,
                          message: "من فضلك اخل اسم العرض بالانجليزي",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ fontSize: 17, direction: "ltr" }}
                        placeholder="اسم العرض"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        onChange={nameValueEN}
                      />
                    </Form.Item>
                  </Col>

                  {countryTrue && (
                    <Col span={12}>
                      <Form.Item
                        label={
                          <div>
                            <h3>المتجر</h3>
                          </div>
                        }
                        name="appliesTo"
                        rules={[
                          { required: true, message: "من فضلك اختر المتجر" },
                        ]}
                      >
                        {/* <Select
                        placeholder="أختر المتجر"
                        onChange={handleStoreChange}
                      >
                        {stores && (
                          <>
                            {stores.map((item) => (
                              <Select.Option
                                style={{ fontSize: 17, direction: "rtl" }}
                                key={item.id}
                                value={item.id} // Use the ID as the value
                              >
                                {FixArabic(item.title)}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select> */}
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الوصف بالعربي</h3>
                        </div>
                      }
                      name="descriptionAR"
                      rules={[
                        {
                          required: true,
                          message: "الرجاء ادخال الوصف بالعربي",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="ادخال الوصف"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={descriptionAR}
                        onChange={handelTypeValueAR}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الوصف بالانجليزي</h3>
                        </div>
                      }
                      name="descriptionEN"
                      rules={[
                        {
                          required: true,
                          message: "الرجاء ادخال الوصف بالانجليزي",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="ادخال الوصف"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={descriptionEN}
                        onChange={handelTypeValueEN}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>كود الخصم</h3>
                        </div>
                      }
                      name="Offer-code"
                      rules={[
                        { required: true, message: "من فضلك ادخل الكود" },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="الكود"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        onChange={(event) => {
                          setVoucherCode(event.target.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>رقم الهاتف</h3>
                        </div>
                      }
                      name="Phone"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="الهاتف"
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={phone}
                        onKeyPress={handleKeyPress}
                        onInput={handleInput}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>كيف ينتهي ؟</h3>
                        </div>
                      }
                      name="promo-end"
                    >
                      <Select
                        placeholder="أختر كيف ينتهي العرض"
                        onChange={(val) => {
                          if (val === "Date") {
                            setDateTrue(true);
                            setPresesTrue(false);
                          } else if (val === "Preses") {
                            setPresesTrue(true);
                            setDateTrue(false);
                          } else if (val === "Both") {
                            setPresesTrue(true);
                            setDateTrue(true);
                          }
                        }}
                      >
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="Date"
                        >
                          بالتاريخ
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="Preses"
                        >
                          عدد الضغطات
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="Both"
                        >
                          كلاهما
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {presesTrue && (
                    <Col span={12}>
                      <Form.Item
                        label={
                          <div>
                            <h3>عدد الضغطات</h3>
                          </div>
                        }
                        name="presses-filed"
                        rules={[
                          {
                            required: true,
                            message: "لم يتم تحديد عدد الضغطات ",
                          },
                        ]}
                      >
                        <Input.TextArea
                          style={{ direction: "rtl" }}
                          placeholder="عدد الضغطات"
                          autoSize={{ minRows: 1, maxRows: 20 }}
                          value={presesValue}
                          onKeyPress={handlePressesKeyPress}
                          onInput={handlePressesInput}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {dateTrue && (
                    <>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <div>
                              <h3>تاريخ البدء</h3>
                            </div>
                          }
                          name="Start-Date"
                        >
                          <Input
                            type="date"
                            value={startDate}
                            autoSize={{ minRows: 1, maxRows: 10 }}
                            onChange={handelStartDateValue}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label={
                            <div>
                              <h3>تاريخ الانتهاء</h3>
                            </div>
                          }
                          name="End-Date"
                        >
                          <Input
                            type="date"
                            value={endDate}
                            autoSize={{ minRows: 1, maxRows: 10 }}
                            onChange={handelEndDateValue}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الموقع الجغرافي</h3>
                        </div>
                      }
                      name="Location"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="https://"
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        value={location}
                        onChange={handelLocationValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>الموقع الألكتروني</h3>
                        </div>
                      }
                      name="Website"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="https://"
                        value={website}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelWebsiteValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>رابط التحميل</h3>
                        </div>
                      }
                      name="Download"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="https://"
                        value={download}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelDownloadValue}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>إضافة صوره</h3>
                        </div>
                      }
                      name="Image"
                    >
                      <Select
                        placeholder="أختر حالة الصور"
                        onChange={handleImageChange}
                      >
                        <Option
                          style={{ direction: "rtl", fontSize: 17 }}
                          value="Disable"
                        >
                          اغلاق
                        </Option>
                        <Option
                          style={{ direction: "rtl", fontSize: 17 }}
                          value="Enable"
                        >
                          تفعيل
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>مكان العرض</h3>
                        </div>
                      }
                      name="promo-Place"
                      rules={[{ required: true, message: "أختر مكان العرض" }]}
                    >
                      <Select
                        placeholder="أختر مكان العرض"
                        onChange={(val) => {
                          if (val === "on") {
                            setPromoPlace(true);
                          } else if (val === "Off") {
                            setPromoPlace(false);
                          } else if (val === "Off&on") {
                            setPromoPlace("Both");
                          }
                        }}
                      >
                        <Option
                          style={{ fontSize: 17, direction: "ltr" }}
                          value="on"
                        >
                          Online
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "ltr" }}
                          value="Off"
                        >
                          Offline
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "ltr" }}
                          value="Off&on"
                        >
                          Online & Offline
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {imageTrue && (
                    <ImgaeSection>
                      <Row>
                        <Form.Item name="Image_en">
                          <p>الصورة انجليزي</p>
                          <ImageUploader
                            imageLang={"en"}
                            setImagedata={setImage_En_data}
                          />
                        </Form.Item>
                        <Form.Item name="Image_ar">
                          <p>الصورة عربي</p>
                          <ImageUploader
                            imageLang={"ar"}
                            setImagedata={setImage_Ar_data}
                          />
                        </Form.Item>
                      </Row>
                    </ImgaeSection>
                  )}
                </Row>
                <ButtonSection>
                  <Button onClick={buttonCliked} variant="success">
                    سجل الكود
                  </Button>
                  <Button
                    onClick={() => {
                      setDeleteButton(true);
                    }}
                    variant="success"
                  >
                    حذف كود مًسجل
                  </Button>
                </ButtonSection>
              </Form>
            </StyledForm>
          )}
        </>
      )}
    </div>
  );
};

export default AddPromotion;
