import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Col } from "antd";

function ImageUploader(props) {
  const { setImagedata } = props;
  const [imageURL, setImageURL] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0]; // Ensure only one file is selected
      if (selectedFile) {
        setImagedata(selectedFile);
        const iURL = URL.createObjectURL(selectedFile);
        setImageURL(iURL);
      }
    },
    [setImagedata]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false, // Allow only one file to be uploaded
  });

  const style = {
    minWidth: "150px",
    position: "relative",
    display: "inline-block",
    border: "2px dashed #ccc",
    borderRadius: "4px",
    padding: "20px",
    boxSizing: "border-box",
    textAlign: "center",
    cursor: "pointer",
  };

  return (
    <div className="centered-container">
      {/* <Row gutter={16}> */}
      <Col span={12}>
        {imageURL !== "" ? (
          <div className="image-drop-zone">
            <img src={imageURL} alt="Uploaded" width="200" />
            <Button
              onClick={() => {
                setImageURL("");
              }}
              style={{ marginTop: 10 }}
              variant="secondary"
            >
              غير الصورة
            </Button>
          </div>
        ) : (
          <div style={style} {...getRootProps()}>
            <input {...getInputProps()} />
            <p>اضغط أو اسحب الصورة هنا</p>
          </div>
        )}
      </Col>

      {/* </Row> */}
    </div>
  );
}

export default ImageUploader;
