import { addDoc, collection } from "@firebase/firestore";
import { firestore } from "../Firebase/firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";

const Promocodes = async (promocodes) => {
  try {
    const ref = collection(firestore, "promocodes"); // Firebase creates this automatically
    let data = {
      promocodes: promocodes,
    };
    addDoc(ref, data);
    return 200;
  } catch (err) {
    console.log("tsts: ", err);
    return 404;
  }
};

const UploadImage = async (imageFile, country, name) => {
  try {
    const originalFileName = imageFile.name;
    const storage = getStorage();
    const imageName = name
      ? name
      : `${country}_${originalFileName}_${Math.floor(
          Math.random() * 10000000
        )}`;
    const storageRef = ref(storage, `images/${imageName})}`);

    await uploadBytesResumable(storageRef, imageFile);
    const path = storageRef.fullPath;
    const downloadURL = await getDownloadURL(storageRef);

    return { status: 200, url: downloadURL, path: path };
  } catch (err) {
    console.error("Error uploading image:", err); // Log the error for debugging
    return { status: 500, error: err.message }; // Return an error status and message
  }
};

export { UploadImage };
export default Promocodes;
