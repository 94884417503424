// src/PhoneAuth.js
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { auth } from "../Firebase/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function PhoneAuth(props) {
  const { isCorrectCode } = props;
  const MySwal = withReactContent(Swal);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const authPhoneList = JSON.parse(process.env.REACT_APP_PHONE_LIST);
  //   const [form] = Form.useForm();

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recabId", {
      size: "invisible",
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        isCorrectCode(true);
      }
    });

    // This will unsubscribe the observer when the component unmounts
    return () => unsubscribe();
  }, [isCorrectCode]);

  const handleSendCode = () => {
    generateRecaptcha();
    const phoneNumberPattern = /^\d{11}$/;

    if (phoneNumberPattern.test(phoneNumber)) {
      if (
        phoneNumber === authPhoneList[0] ||
        phoneNumber === authPhoneList[1] ||
        phoneNumber === authPhoneList[2] ||
        phoneNumber === authPhoneList[3] ||
        phoneNumber === authPhoneList[4] ||
        phoneNumber === authPhoneList[5]
      ) {
        const appVerifier = window.recaptchaVerifier;
        MySwal.fire({
          title: (
            <div>
              <h3 style={{ color: "blue" }}>جاري إرسال الكود</h3>
            </div>
          ),
          didOpen: () => {
            MySwal.showLoading();
          },
        });
        signInWithPhoneNumber(auth, `+2${phoneNumber}`, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setCodeSent(true);
            MySwal.close();
          })
          .catch((error) => {
            console.log(error);
            MySwal.fire(
              <div>
                <h1 style={{ color: "red" }}>
                  حدث خطاء الرجاءالمحاولة مره اخرى
                </h1>
              </div>
            );
          });
      } else {
        MySwal.fire(
          <div>
            <h1 style={{ color: "red" }}>حدث خطاء الرجاءالتاكد من الرقم</h1>

            <p>الرقم غير صحيح</p>
          </div>
        );
      }
    } else {
      MySwal.fire(
        <div>
          <h1 style={{ color: "red" }}>حدث خطاء الرجاءالتاكد من الرقم</h1>
          <p> مثال</p>
          <p>01234567890</p>
        </div>
      );
    }
  };

  const handleVerifyCode = () => {
    MySwal.fire({
      title: (
        <div>
          <h3 style={{ color: "blue" }}>جاري التحقق من الكود</h3>
        </div>
      ),

      didOpen: () => {
        MySwal.showLoading();
      },
    });
    if (code.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;

          isCorrectCode(true);
          MySwal.close();
        })
        .catch((error) => {
          isCorrectCode(false);
          MySwal.fire(
            <div>
              <h1 style={{ color: "red" }}>فشل الدخول</h1>
              <p>خطاء في الكود</p>
            </div>
          );
        });
    } else {
      setTimeout(() => {
        MySwal.fire(
          <div>
            <h3 style={{ color: "red" }}>فشل الدخول</h3>
            <p>الرجاء إدخال ال٦ اقام في الرسالة النصية</p>
          </div>
        );
      }, 500);
    }
  };

  const handleKeyPress = (e) => {
    const isNumeric = /^[0-9]$/;
    if (!isNumeric.test(e.key)) {
      e.preventDefault();
    }
  };
  const handleInput = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    setPhoneNumber(numericValue);
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <Row className="justify-content-center">
        <Col xs={9} sm={7} md={5}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                placeholder="01234567890"
                value={phoneNumber}
                onKeyPress={handleKeyPress}
                onInput={handleInput}
              />
            </Form.Group>

            {codeSent && (
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  type="password"
                  placeholder="Code"
                />
              </Form.Group>
            )}
            {!codeSent ? (
              <Button onClick={handleSendCode} variant="success">
                Get Code
              </Button>
            ) : (
              <Button onClick={handleVerifyCode} variant="success">
                Verify Code
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      <div id="recabId" />
    </Container>
  );
}

export default PhoneAuth;
