import { Col, Form, Input, Row, Select } from "antd";
import { Button } from "react-bootstrap";
import React, { memo, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ButtonSection,
  Header,
  ImgaeSection,
  StyledForm,
} from "../Style/styles";
import ImageUploader from "./ImageUploader";

import { handelSignOut } from "../Firebase/firebase";
import PhoneAuth from "../Firebase-Auth/PhoneAuth";
import RemovePromocode from "./remove-promocode";

import OneTimeOffer from "../API-Call/OneTimeView";
const { Option } = Select;

export const AdImage = memo(({ setShowPromCode }) => {
  const MySwal = withReactContent(Swal);

  const [form] = Form.useForm();
  const [isCorrectCode, setIsCorrectCode] = useState(false);
  const [download, setDownload] = useState("https://app.tsawq.me");
  const [image_En_Data, setImage_En_data] = useState("undefined");
  const [image_Ar_Data, setImage_Ar_data] = useState("undefined");
  const [country, setCountry] = useState(null);
  const [deletButtonClicked, setDeleteButton] = useState(false);
  const [adPlace, setAdPlace] = useState("default");

  const backPressed = () => {
    setShowPromCode(false);
  };

  // The Download Date Imput
  const handelDownloadValue = (event) => {
    setDownload(event.target.value);
  };

  // The Main object
  const defaultAd = {
    image_en: "",
    imagePath_en: "",
    image_ar: "",
    imagePath_ar: "",
    download,
    country,
  };

  // Country
  const handleCountryChange = (value) => {
    setCountry(value);
  };

  // The Submit Button Founction Start -->
  const buttonCliked = async () => {
    if (
      (country === null) &
        (image_Ar_Data === "undefined" || image_En_Data === "undefined") ||
      (image_Ar_Data === "undefined" && image_En_Data === "undefined")
    ) {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "red" }}>فشل التسجيل</h3>
            <p>تأكد من كل الخانات الإجبارية</p>
          </div>
        ),
        didOpen: () => {
          MySwal.showLoading();
        },
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
    } else {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "blue" }}>جاري التسجيل</h3>
          </div>
        ),

        didOpen: () => {
          MySwal.showLoading();
        },
      });

      if (country) {
        await fireStoreCall(defaultAd, image_En_Data, image_Ar_Data);
      }
    }
  };

  // <-- The Submit Button Founction End

  // Saveing To Firestore Function Start -->
  async function fireStoreCall(defaultAd, image_En_Data, image_Ar_Data) {
    console.log(image_En_Data);
    console.log(image_Ar_Data);
    await OneTimeOffer(
      defaultAd,
      JSON.stringify(country) === "true"
        ? ["eg", "sa", "kw", "om", "ae", "bh", "qa"]
        : [country],
      image_En_Data,
      image_Ar_Data,
      adPlace
    ).then((val) => {
      console.log(val);
      setTimeout(() => {
        MySwal.close();
      }, 500);

      setTimeout(() => {
        if (val) {
          if (val === 200 || val[0] === 200) {
            form.resetFields();
            setImage_Ar_data("undefined");
            setImage_En_data("undefined");
            setCountry(null);
            return MySwal.fire(
              <div>
                <h3 style={{ color: "green" }}>تم التسجيل</h3>
              </div>
            );
          } else {
            return MySwal.fire(
              <div>
                <h3 style={{ color: "red" }}>فشل التسجيل</h3>
              </div>
            );
          }
        }
      }, 800);
    });
  }

  return (
    <div>
      <Header>
        {isCorrectCode && (
          <Button
            onClick={() => {
              handelSignOut(setIsCorrectCode);
            }}
            variant="danger"
          >
            تسجيل خروج
          </Button>
          // backPressed
        )}
        {isCorrectCode && (
          <Button
            onClick={backPressed}
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              fontSize: 20,
              marginLeft: "1%",
            }}
          >
            القائمة الرائيسية
          </Button>
        )}
      </Header>
      <h1 style={{ margin: 20, color: "#f1fae3", fontWeight: "bold" }}>
        Send one Time View
      </h1>

      {!isCorrectCode ? (
        <PhoneAuth isCorrectCode={setIsCorrectCode} />
      ) : (
        <>
          {deletButtonClicked ? (
            <RemovePromocode setDeleteButton={setDeleteButton} />
          ) : (
            <StyledForm form={form} layout="vertical">
              <Form
                style={{
                  direction: "rtl",
                }}
                form={form}
                layout="vertical"
              >
                <Col span={12}>
                  <Form.Item
                    label={
                      <div>
                        <h3>البلد</h3>
                      </div>
                    }
                    name="Country"
                    rules={[
                      { required: true, message: "Please select Country!" },
                    ]}
                  >
                    <Select
                      placeholder="اختر البلد"
                      onChange={handleCountryChange}
                    >
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value={true}
                      >
                        الكل
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="eg"
                      >
                        مصر
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="sa"
                      >
                        المملكة السعودية
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="kw"
                      >
                        الكويت
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="ae"
                      >
                        الأمارات
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="qa"
                      >
                        قطر
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="bh"
                      >
                        البحرين
                      </Option>
                      <Option
                        style={{ fontSize: 17, direction: "rtl" }}
                        value="om"
                      >
                        عمان
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={
                      <div>
                        <h3>رابط</h3>
                      </div>
                    }
                    name="Download"
                  >
                    <Input.TextArea
                      style={{ direction: "ltr" }}
                      placeholder="https://"
                      value={download}
                      autoSize={{ minRows: 1, maxRows: 10 }}
                      onChange={handelDownloadValue}
                    />
                  </Form.Item>
                </Col>
                {/* {imageTrue && ( */}
                <ImgaeSection>
                  <Row>
                    <Form.Item name="Image_en">
                      <p>الصورة انجليزي</p>
                      <ImageUploader
                        imageLang={"en"}
                        setImagedata={setImage_En_data}
                      />
                    </Form.Item>
                    <Form.Item name="Image_ar">
                      <p>الصورة عربي</p>
                      <ImageUploader
                        imageLang={"ar"}
                        setImagedata={setImage_Ar_data}
                      />
                    </Form.Item>
                  </Row>
                </ImgaeSection>
                {/* )} */}
                {/* </Row> */}
                <ButtonSection>
                  <Button onClick={buttonCliked} variant="success">
                    سجل الاعلان
                  </Button>
                  {/* <Button
                    onClick={() => {
                      setDeleteButton(true);
                    }}
                    variant="success"
                  >
                    حذف كود مًسجل
                  </Button> */}
                </ButtonSection>
              </Form>
            </StyledForm>
          )}
        </>
      )}
    </div>
  );
});
