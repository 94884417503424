import {
  getFirestore,
  collection,
  doc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { UploadImage } from "./Promocodes";

const OneTimeOffer = async (
  oneTimeOffer,
  country,
  image_En_Data,
  image_Ar_Data,
  adPlace
) => {
  try {
    const collectionName =
      adPlace === "Ad1"
        ? "ONE_TIME_OFFER"
        : adPlace === "default"
        ? "default"
        : "REPLACE_GOOGLE_ADS";
    const db = getFirestore();
    const collectionRef = collection(db, collectionName);

    for (const countryCode of country) {
      const customDocRef = doc(collectionRef, countryCode);
      await deleteDoc(customDocRef);

      const imageEnResult =
        image_En_Data !== "undefined"
          ? await UploadImage(
              image_En_Data,
              countryCode,
              adPlace === "default" ? "En-Image-ad" : false
            )
          : false;
      const imageArResult =
        image_Ar_Data !== "undefined"
          ? await UploadImage(
              image_Ar_Data,
              countryCode,
              adPlace === "default" ? "Ar-Image-ad" : false
            )
          : false;

      oneTimeOffer.image_en = imageEnResult ? imageEnResult.url : "undefined";
      oneTimeOffer.imagePath_en = imageEnResult
        ? imageEnResult.path
        : "undefined";
      oneTimeOffer.image_ar = imageArResult ? imageArResult.url : "undefined";
      oneTimeOffer.imagePath_ar = imageArResult
        ? imageArResult.path
        : "undefined";

      const newData = {
        oneTimeOffer,
      };

      await setDoc(customDocRef, newData);
    }

    return 200;
  } catch (err) {
    console.error("Error updating OneTimeOffer:", err);
    return 400;
  }
};

export default OneTimeOffer;
