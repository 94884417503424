import styled from "styled-components";
import { Button, Form } from "antd";

const StyledForm = styled(Form)`
  margin-bottom: 3rem;
  @media screen and (min-width: 741px) {
    max-width: 80vw;

    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 1rem;
    h3 {
      padding: 1rem;
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  }
  @media screen and (min-height: 741px) {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 1rem;
    h3 {
      padding: 1rem;
      margin-top: 1rem;
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width: 741px) {
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 1rem;
    h3 {
      padding: 0.3 rem;

      font-size: 1rem;
    }
  }
  @media screen and (max-width: 280px) {
    max-width: 100vw;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 1rem;
    h3 {
      padding: 0.3 rem;

      font-size: 0.9rem;
    }
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  @media screen and (max-width: 280px) {
    back
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  flex-direction: column;
`;

const ButtonSection = styled.div`
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 742px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 30vw;
    flex-direction: row;
  }
  @media screen and (max-width: 741px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 80vw;
    flex-direction: row;
  }
  @media screen and (max-width: 280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    width: 80vw;
    flex-direction: row;
  }
`;
const ImgaeSection = styled.div`
  margin: 2rem;
`;

const Header = styled.div`
  @media screen and (min-width: 1080px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;
    margin-bottom: -4rem;
    margin-right: 11rem;
  }
  @media screen and (min-width: 742px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    margin-bottom: -4rem;
    margin-right: 10rem;
  }

  @media screen and (max-width: 741px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: -2rem;
    margin-right: 1rem;
  }
  @media screen and (max-width: 460px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }
`;
export {
  ImgaeSection,
  ButtonSection,
  CenteredContainer,
  StyledButton,
  StyledForm,
  Header,
};
