import { Col, Form, Input, Row, Select } from "antd";
import { Button } from "react-bootstrap";
import React, { memo, useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  ButtonSection,
  Header,
  ImgaeSection,
  StyledForm,
} from "../Style/styles";
import ImageUploader from "./ImageUploader";
// import { UploadImage } from "../API-Call/Promocodes";
import currentDate from "../constant/currentDate";
import TimeToEnd from "../constant/TimeToEnd";
import { handelSignOut } from "../Firebase/firebase";
import PhoneAuth from "../Firebase-Auth/PhoneAuth";
import RemovePromocode from "./remove-promocode";
import Search from "../component/Search";
import OneTimeOffer from "../API-Call/OneTimeView";
const { Option } = Select;

export const OneTimeView = memo(({ setShowPromCode }) => {
  const MySwal = withReactContent(Swal);
  const date = currentDate();
  const [form] = Form.useForm();
  const [isCorrectCode, setIsCorrectCode] = useState(false);
  const [imageTrue, setImageTrue] = useState(false);
  const [image_En_Data, setImage_En_data] = useState("");
  const [image_Ar_Data, setImage_Ar_data] = useState("");
  const [download, setDownload] = useState("");
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState("");
  const [country, setCountry] = useState(null);
  const [descriptionAR, setDescriptionAR] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [deletButtonClicked, setDeleteButton] = useState(false);
  const [offerData, setOfferData] = useState(false);
  const [disableDescriptionEN, setDisableDescriptionEN] = useState(false);
  const [disableDescriptionAR, setDisableDescriptionAR] = useState(false);
  const [downloadLinkName_ar, setDownloadLinkName_ar] = useState(false);
  const [downloadLinkName_en, setDownloadLinkName_en] = useState(false);
  const [adPlace, setAdPlace] = useState(false);

  const backPressed = () => {
    setShowPromCode(false);
  };
  useEffect(() => {
    if (offerData) {
      setDisableDescriptionEN(true);
      setDisableDescriptionAR(true);
      handelTypeValueEN(offerData.translated_title);
      handelTypeValueAR(offerData.title);
    } else {
      setDisableDescriptionEN("");
      setDisableDescriptionAR("");
      setDisableDescriptionEN(false);
      setDisableDescriptionAR(false);
    }
  }, [offerData]);

  // The Main object
  const OneTimeViewObject = {
    id: "",
    image_en: "",
    imagePath_en: "",
    image_ar: "",
    imagePath_ar: "",
    country,
    descriptionAR,
    descriptionEN,
    download,
    startDate,
    endDate,
    offerData,
    sawItArray: [],
  };

  // Country
  const handleCountryChange = (value) => {
    setCountry(value);
  };
  //ad
  const handleAdChange = (value) => {
    setAdPlace(value);
  };

  // The Image container show
  const handleImageChange = (value) => {
    if (value === "Enable") {
      setImageTrue(true);
    } else {
      setImageTrue(false);
    }
  };

  // The Description Arabic Imput
  const handelTypeValueAR = (event) => {
    if (typeof event === "string") {
      setDescriptionAR(event);
      return;
    }
    setDescriptionAR(event.target.value);
  };

  // The Description English Imput
  const handelTypeValueEN = (event) => {
    if (typeof event === "string") {
      setDescriptionEN(event);
      return;
    }
    setDescriptionEN(event.target.value);
  };

  // The Download Date Imput
  const handelDownloadValue = (event) => {
    setDownload(event.target.value);
  };

  // The Start Date Imput
  const handelStartDateValue = (event) => {
    setStartDate(event.target.value);
  };

  // The End Date Imput
  const handelEndDateValue = (event) => {
    setEndDate(event.target.value);
  };

  const handelDownloadLinkName = (event) => {
    setDownloadLinkName_ar(event.target.value);
  };
  const handelDownloadLinkName_en = (event) => {
    setDownloadLinkName_en(event.target.value);
  };
  // The Submit Button Founction Start -->
  const buttonCliked = async () => {
    const remainingDays = TimeToEnd(startDate, endDate);
    OneTimeViewObject.remainingTime = remainingDays;
    OneTimeViewObject.downloadLinkName_ar = downloadLinkName_ar;
    OneTimeViewObject.downloadLinkName_en = downloadLinkName_en;

    if (country === "" || endDate === "" || !adPlace) {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "red" }}>فشل التسجيل</h3>
            <p>تأكد من كل الخانات الإجبارية</p>
          </div>
        ),
        didOpen: () => {
          MySwal.showLoading();
        },
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
    } else {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "blue" }}>جاري التسجيل</h3>
          </div>
        ),

        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // const countryModifyed =
      //   JSON.stringify(country) === "true"
      //     ? ["eg", "sa", "kw", "om", "ae", "bh", "qa"]
      //     : [country];
      // countryModifyed.map(async (countryCode) => {
      //   if (image_En_Data !== "") {
      //     await UploadImage(image_En_Data, countryCode).then(async (val) => {
      //       OneTimeViewObject.image_en = val.url;
      //       OneTimeViewObject.imagePath_en = val.path;
      //     });
      //   } else {
      //     OneTimeViewObject.image_en = "undefined";
      //     OneTimeViewObject.imagePath_en = "undefined";
      //   }
      //   if (image_Ar_Data !== "") {
      //     await UploadImage(image_Ar_Data, countryCode).then(async (val) => {
      //       OneTimeViewObject.image_ar = val.url;
      //       OneTimeViewObject.imagePath_ar = val.path;
      //       await fireStoreCall(OneTimeViewObject);
      //     });
      //   } else {
      //     OneTimeViewObject.image_ar = "undefined";
      //     OneTimeViewObject.imagePath_ar = "undefined";
      //     await fireStoreCall(OneTimeViewObject);
      //   }
      // });
      // async function uploadImagesAndCallFirestore(
      //   image_En_Data,
      //   image_Ar_Data,
      //   country
      // ) {
      //   const countryModified =
      //     JSON.stringify(country) === "true"
      //       ? ["eg", "sa", "kw", "om", "ae", "bh", "qa"]
      //       : [country];

      //   for (const countryCode of countryModified) {
      //     const OneTimeViewObject = {}; // Create an object to store the view data

      //     if (image_En_Data !== "") {
      //       const val = await UploadImage(image_En_Data, countryCode);
      //       OneTimeViewObject.image_en = val.url;
      //       OneTimeViewObject.imagePath_en = val.path;
      //     } else {
      //       OneTimeViewObject.image_en = "undefined";
      //       OneTimeViewObject.imagePath_en = "undefined";
      //     }

      //     if (image_Ar_Data !== "") {
      //       const val = await UploadImage(image_Ar_Data, countryCode);
      //       OneTimeViewObject.image_ar = val.url;
      //       OneTimeViewObject.imagePath_ar = val.path;
      //     } else {
      //       OneTimeViewObject.image_ar = "undefined";
      //       OneTimeViewObject.imagePath_ar = "undefined";
      //     }
      if (adPlace) {
        await fireStoreCall(OneTimeViewObject, image_En_Data, image_Ar_Data);
      }
      //   }
      // }

      // Call the function
      // await uploadImagesAndCallFirestore(image_En_Data, image_Ar_Data, country);
    }
  };

  // <-- The Submit Button Founction End

  // Saveing To Firestore Function Start -->
  async function fireStoreCall(
    OneTimeViewObject,
    image_En_Data,
    image_Ar_Data
  ) {
    await OneTimeOffer(
      OneTimeViewObject,
      JSON.stringify(country) === "true"
        ? ["eg", "sa", "kw", "om", "ae", "bh", "qa"]
        : [country],
      image_En_Data,
      image_Ar_Data,
      adPlace
    ).then((val) => {
      console.log(val);
      setTimeout(() => {
        MySwal.close();
      }, 500);

      setTimeout(() => {
        if (val) {
          if (val === 200 || val[0] === 200) {
            form.resetFields();
            setImageTrue(false);
            setCountry("");
            setStartDate(date);
            setEndDate("");
            setDescriptionAR("");
            setDescriptionEN("");
            setDownload("");
            return MySwal.fire(
              <div>
                <h3 style={{ color: "green" }}>تم التسجيل</h3>
              </div>
            );
          } else {
            return MySwal.fire(
              <div>
                <h3 style={{ color: "red" }}>فشل التسجيل</h3>
              </div>
            );
          }
        }
      }, 800);
    });
  }

  return (
    <div>
      <Header>
        {isCorrectCode && (
          <Button
            onClick={() => {
              handelSignOut(setIsCorrectCode);
            }}
            variant="danger"
          >
            تسجيل خروج
          </Button>
          // backPressed
        )}
        {isCorrectCode && (
          <Button
            onClick={backPressed}
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              fontSize: 20,
              marginLeft: "1%",
            }}
          >
            القائمة الرائيسية
          </Button>
        )}
      </Header>
      <h1 style={{ margin: 20, color: "#f1fae3", fontWeight: "bold" }}>
        Send one Time View
      </h1>

      {!isCorrectCode ? (
        <PhoneAuth isCorrectCode={setIsCorrectCode} />
      ) : (
        <>
          {deletButtonClicked ? (
            <RemovePromocode setDeleteButton={setDeleteButton} />
          ) : (
            <StyledForm form={form} layout="vertical">
              <Form
                style={{
                  direction: "rtl",
                }}
                form={form}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>البلد</h3>
                        </div>
                      }
                      name="Country"
                      rules={[
                        { required: true, message: "Please select Country!" },
                      ]}
                    >
                      <Select
                        placeholder="اختر البلد"
                        onChange={handleCountryChange}
                      >
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value={true}
                        >
                          الكل
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="eg"
                        >
                          مصر
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="sa"
                        >
                          المملكة السعودية
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="kw"
                        >
                          الكويت
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="ae"
                        >
                          الأمارات
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="qa"
                        >
                          قطر
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="bh"
                        >
                          البحرين
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="om"
                        >
                          عمان
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>مكان الاعلان</h3>
                        </div>
                      }
                      name="Ads"
                      rules={[
                        { required: true, message: "Please select Country!" },
                      ]}
                    >
                      <Select
                        placeholder="اختر مكان الاعلان"
                        onChange={handleAdChange}
                      >
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="Ad1"
                        >
                          مره واحدة مع بداية التطبيق
                        </Option>
                        <Option
                          style={{ fontSize: 17, direction: "rtl" }}
                          value="Ad2"
                        >
                          مكان اعلان جوجل في حالة لم يظهر
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>اختر العرض</h3>
                        </div>
                      }
                      name=""
                    >
                      <Search setOfferData={setOfferData} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>وصف الصورة بالعربي</h3>
                        </div>
                      }
                      name="descriptionAR"
                    >
                      <Input.TextArea
                        placeholder={
                          disableDescriptionAR ? descriptionAR : "ادخال الوصف"
                        }
                        disabled={disableDescriptionAR}
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={descriptionAR}
                        onChange={handelTypeValueAR}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>وصف الصورة بالانجليزي</h3>
                        </div>
                      }
                      name="descriptionEN"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder={
                          disableDescriptionEN ? descriptionEN : "ادخال الوصف"
                        }
                        disabled={disableDescriptionEN}
                        autoSize={{ minRows: 1, maxRows: 20 }}
                        value={descriptionEN}
                        onChange={handelTypeValueEN}
                      />
                    </Form.Item>
                  </Col>

                  {/* {dateTrue && (
                    <> */}
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>تاريخ البدء</h3>
                        </div>
                      }
                      name="Start-Date"
                    >
                      <Input
                        type="date"
                        value={startDate}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelStartDateValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>تاريخ الانتهاء</h3>
                        </div>
                      }
                      name="End-Date"
                    >
                      <Input
                        type="date"
                        value={endDate}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelEndDateValue}
                      />
                    </Form.Item>
                  </Col>
                  {/* </>
                  )} */}

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>رابط</h3>
                        </div>
                      }
                      name="Download"
                    >
                      <Input.TextArea
                        style={{ direction: "ltr" }}
                        placeholder="https://"
                        value={download}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelDownloadValue}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>اسم الرابط عربي</h3>
                        </div>
                      }
                      name="DownloadNameAR"
                    >
                      <Input.TextArea
                        style={{ direction: "rtl" }}
                        placeholder="اسم الرابط"
                        value={downloadLinkName_ar}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelDownloadLinkName}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>اسم الرابط انجليزي</h3>
                        </div>
                      }
                      name="DownloadNameEN"
                    >
                      <Input.TextArea
                        style={{ direction: "rtl" }}
                        placeholder="اسم الرابط"
                        value={downloadLinkName_en}
                        autoSize={{ minRows: 1, maxRows: 10 }}
                        onChange={handelDownloadLinkName_en}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={
                        <div>
                          <h3>إضافة صوره</h3>
                        </div>
                      }
                      name="Image"
                    >
                      <Select
                        placeholder="أختر حالة الصور"
                        onChange={handleImageChange}
                      >
                        <Option
                          style={{ direction: "rtl", fontSize: 17 }}
                          value="Disable"
                        >
                          اغلاق
                        </Option>
                        <Option
                          style={{ direction: "rtl", fontSize: 17 }}
                          value="Enable"
                        >
                          تفعيل
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {imageTrue && (
                    <ImgaeSection>
                      <Row>
                        <Form.Item name="Image_en">
                          <p>الصورة انجليزي</p>
                          <ImageUploader
                            imageLang={"en"}
                            setImagedata={setImage_En_data}
                          />
                        </Form.Item>
                        <Form.Item name="Image_ar">
                          <p>الصورة عربي</p>
                          <ImageUploader
                            imageLang={"ar"}
                            setImagedata={setImage_Ar_data}
                          />
                        </Form.Item>
                      </Row>
                    </ImgaeSection>
                  )}
                </Row>
                <ButtonSection>
                  <Button onClick={buttonCliked} variant="success">
                    سجل الاعلان
                  </Button>
                  {/* <Button
                    onClick={() => {
                      setDeleteButton(true);
                    }}
                    variant="success"
                  >
                    حذف كود مًسجل
                  </Button> */}
                </ButtonSection>
              </Form>
            </StyledForm>
          )}
        </>
      )}
    </div>
  );
});
