import React, { useState } from "react";
import { Form, Input, Select, Row, Col } from "antd";
import { Button } from "react-bootstrap";
import ImageUploader from "./ImageUploader";
import { handelSignOut } from "../Firebase/firebase";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UploadImage } from "../API-Call/Promocodes";
import { StyledForm, ImgaeSection, Header } from "../Style/styles";
import PhoneAuth from "../Firebase-Auth/PhoneAuth";
import sendNotificationCall from "../API-Call/sendNotificationCall";

const { Option } = Select;

const SendNotification = ({ setShowPromCode }) => {
  const MySwal = withReactContent(Swal);
  const [form] = Form.useForm();
  const [countryTrue, setCountryTrue] = useState(false);
  const [isCorrectCode, setIsCorrectCode] = useState(false);
  const [imageTrue, setImageTrue] = useState(false);
  const [importantImage_Data, setimportantImage_data] = useState("");
  const [nameAR, setNameAR] = useState(undefined);
  const [nameEN, setNameEN] = useState(undefined);
  const [country, setCountry] = useState("");
  const [descriptionAR, setDescriptionAR] = useState("");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [website, setWebsite] = useState("");

  const backPressed = () => {
    setShowPromCode(false);
  };

  const currentDate = new Date();
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  const formattedDate = currentDate.toLocaleString("en-US", options);

  // The Main object
  const sendNotification = {
    data: {
      offerid: `${formattedDate}S`,
      id: `${formattedDate}s`,
      country: country,
      translated_title: descriptionEN,
      title: descriptionAR,
      notificationTitleAr: nameAR,
      notificationTitleEn: nameEN,
      link: website,
      importantImage: undefined,
      imagePath_en: "",
      tsawq: "me",
    },
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  // The Image container show
  const handleImageChange = (value) => {
    if (value === "Enable") {
      setImageTrue(true);
    } else {
      setImageTrue(false);
    }
  };

  // The Name  EN  Imput
  const nameValueAR = (event) => {
    setNameAR(event.target.value);
  };

  //handel link
  const handelWebsiteValue = (event) => {
    setWebsite(event.target.value);
  };

  // The Name  AR  Imput
  const nameValueEN = (event) => {
    setNameEN(event.target.value);
  };

  // The Description Arabic Imput
  const handelTypeValueAR = (event) => {
    setDescriptionAR(event.target.value);
  };

  // The Description English Imput
  const handelTypeValueEN = (event) => {
    setDescriptionEN(event.target.value);
  };

  // The Submit Button Founction Start -->
  const buttonCliked = async () => {
    if (country === "" || descriptionAR === "" || descriptionEN === "") {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "red" }}>فشل التسجيل</h3>
            <p>تأكد من كل الخانات الإجبارية</p>
          </div>
        ),
        didOpen: () => {
          MySwal.showLoading();
        },
      });
      setTimeout(() => {
        MySwal.close();
      }, 2000);
    } else {
      MySwal.fire({
        title: (
          <div>
            <h3 style={{ color: "blue" }}>جاري الإرسال</h3>
          </div>
        ),

        didOpen: () => {
          MySwal.showLoading();
        },
      });
      if (importantImage_Data !== "") {
        await UploadImage(importantImage_Data).then(async (val) => {
          sendNotification.data.importantImage = val.url;
          sendNotification.data.imagePath_en = val.path;
          await fireStoreCall(sendNotification);
        });
      } else {
        sendNotification.data.importantImage = "undefined";
        sendNotification.data.imagePath_en = "undefined";
        await fireStoreCall(sendNotification);
      }
    }
  };

  // <-- The Submit Button Founction End

  // Saveing To Firestore Function Start -->
  async function fireStoreCall(sendNotification) {
    const notificationResponde = await sendNotificationCall(sendNotification);
    if (notificationResponde) {
      MySwal.close();
      form.resetFields();
      setCountryTrue(false);
      setImageTrue(false);
      setCountry("");
      setNameAR(undefined);
      setNameEN(undefined);
      setDescriptionAR("");
      setDescriptionEN("");
      setWebsite("");
      setimportantImage_data("");
      sendNotification.data.offerid = "";
      sendNotification.data.id = "";
      sendNotification.data.importantImage = undefined;
      return MySwal.fire(
        <div>
          <h3 style={{ color: "green" }}> {notificationResponde}</h3>
        </div>
      );
    } else {
      return MySwal.fire(
        <div>
          <h3 style={{ color: "red" }}>فشل التسجيل</h3>
        </div>
      );
    }
  }
  // <-- Saveing To Firestore Function End

  return (
    <div>
      <Header>
        {isCorrectCode && (
          <Button
            onClick={() => {
              handelSignOut(setIsCorrectCode);
            }}
            variant="danger"
          >
            تسجيل خروج
          </Button>
          // backPressed
        )}
        {isCorrectCode && (
          <Button
            onClick={backPressed}
            style={{
              backgroundColor: "#000",
              borderColor: "#000",
              fontSize: 20,
              marginLeft: "1%",
            }}
          >
            القائمة الرائيسية
          </Button>
        )}
      </Header>
      <h1 style={{ margin: 20, color: "#f1fae3", fontWeight: "bold" }}>
        Send Notification
      </h1>

      {!isCorrectCode ? (
        <PhoneAuth isCorrectCode={setIsCorrectCode} />
      ) : (
        <StyledForm form={form} layout="vertical">
          <Form
            style={{
              direction: "rtl",
            }}
            form={form}
            layout="vertical"
          >
            <Col span={12}>
              <Form.Item
                label={
                  <div>
                    <h3>البلد</h3>
                  </div>
                }
                name="Country"
                rules={[{ required: true, message: "Please select Country!" }]}
              >
                <Select placeholder="اختر البلد" onChange={handleCountryChange}>
                  <Option
                    style={{ fontSize: 17, direction: "rtl" }}
                    value="all"
                  >
                    الكل
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="eg">
                    مصر
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="sa">
                    المملكة السعودية
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="kw">
                    الكويت
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="ae">
                    الأمارات
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="qa">
                    قطر
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="bh">
                    البحرين
                  </Option>
                  <Option style={{ fontSize: 17, direction: "rtl" }} value="om">
                    عمان
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>الأسم بالعربي</h3>
                    </div>
                  }
                  name="promoName-ar"
                  rules={[
                    {
                      required: true,
                      message: "من فضلك اخل اسم العرض بالعربي",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ fontSize: 17 }}
                    placeholder="اسم العرض"
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    onChange={nameValueAR}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>الأسم بالانجليزي</h3>
                    </div>
                  }
                  name="promoName-en"
                  rules={[
                    {
                      required: true,
                      message: "من فضلك اخل اسم العرض بالانجليزي",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ fontSize: 17, direction: "ltr" }}
                    placeholder="اسم العرض"
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    onChange={nameValueEN}
                  />
                </Form.Item>
              </Col>

              {countryTrue && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <div>
                        <h3>المتجر</h3>
                      </div>
                    }
                    name="appliesTo"
                    rules={[{ required: true, message: "من فضلك اختر المتجر" }]}
                  ></Form.Item>
                </Col>
              )}

              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>الوصف بالعربي</h3>
                    </div>
                  }
                  name="descriptionAR"
                  rules={[
                    {
                      required: true,
                      message: "الرجاء ادخال الوصف بالعربي",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="ادخال الوصف"
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    value={descriptionAR}
                    onChange={handelTypeValueAR}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>الوصف بالانجليزي</h3>
                    </div>
                  }
                  name="descriptionEN"
                  rules={[
                    {
                      required: true,
                      message: "الرجاء ادخال الوصف بالانجليزي",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ direction: "ltr" }}
                    placeholder="ادخال الوصف"
                    autoSize={{ minRows: 1, maxRows: 20 }}
                    value={descriptionEN}
                    onChange={handelTypeValueEN}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>إضافة لينك</h3>
                    </div>
                  }
                  name="Website"
                >
                  <Input.TextArea
                    style={{ direction: "ltr" }}
                    placeholder="https://"
                    value={website}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    onChange={handelWebsiteValue}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={
                    <div>
                      <h3>إضافة صوره</h3>
                    </div>
                  }
                  name="Image"
                >
                  <Select
                    placeholder="أختر حالة الصور"
                    onChange={handleImageChange}
                  >
                    <Option
                      style={{ direction: "rtl", fontSize: 17 }}
                      value="Disable"
                    >
                      اغلاق
                    </Option>
                    <Option
                      style={{ direction: "rtl", fontSize: 17 }}
                      value="Enable"
                    >
                      تفعيل
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              {imageTrue && (
                <ImgaeSection>
                  <Form.Item name="importantImage">
                    <ImageUploader
                      imageLang={"en"}
                      setImagedata={setimportantImage_data}
                    />
                  </Form.Item>
                </ImgaeSection>
              )}
            </Row>

            <Button
              style={{ margin: "5%", marginBottom: "2%" }}
              onClick={buttonCliked}
              variant="success"
            >
              إرسال
            </Button>
          </Form>
        </StyledForm>
      )}
    </div>
  );
};

export default SendNotification;
