import "./App.css";
import AddPromotion from "./screens/add-promocode";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Row, Col } from "antd";
import { useState } from "react";
import SendNotification from "./screens/SendNotification";
import { OneTimeView } from "./screens/OneTimeView";
import { AdImage } from "./screens/adImage";

function App() {
  const [form] = Form.useForm();
  const [showPromCode, setShowPromCode] = useState(false);

  const PromoButtonClicked = () => {
    setShowPromCode("promo");
  };
  const oneTimeView = () => {
    setShowPromCode("oneTimeView");
  };
  const adImageFunc = () => {
    setShowPromCode("adImage");
  };
  const NotificationbuttonClicked = () => {
    setShowPromCode("notification");
  };
  return (
    <div className="App">
      {!showPromCode && (
        <>
          <h1 style={{ margin: 20, color: "#f1fae3", fontWeight: "bold" }}>
            القائمة الرائيسية
          </h1>
          <Form form={form} className="align-items-center" layout="horizontal">
            <Row justify={"center"}>
              <Col>
                <Form.Item>
                  <Button
                    onClick={PromoButtonClicked}
                    style={{
                      height: 200,

                      minWidth: "250px",
                      fontWeight: "bold",
                      margin: "3rem",
                      fontSize: 18,
                    }}
                  >
                    Promo Code
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    onClick={NotificationbuttonClicked}
                    style={{
                      height: 200,

                      minWidth: "250px",
                      margin: "3rem",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Send Notification
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    onClick={oneTimeView}
                    style={{
                      height: 200,
                      minWidth: "250px",
                      margin: "3rem",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Send one time view
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    onClick={adImageFunc}
                    style={{
                      height: 200,
                      minWidth: "250px",
                      margin: "3rem",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Ad-Image
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
      {showPromCode === "promo" && (
        <AddPromotion setShowPromCode={setShowPromCode} />
      )}
      {showPromCode === "notification" && (
        <SendNotification setShowPromCode={setShowPromCode} />
      )}
      {showPromCode === "oneTimeView" && (
        <OneTimeView setShowPromCode={setShowPromCode} />
      )}
      {showPromCode === "adImage" && (
        <AdImage setShowPromCode={setShowPromCode} />
      )}
    </div>
  );
}

export default App;

const callApi = () => {
  const url = "http://localhost:3003/twitter-api";

  // Example POST data (replace with your actual data)
  const postData = {
    text: "Tweeting a DM deep2 link!",
  };

  // Make the API call using the Fetch API
  fetch(url, {
    method: "POST",
    body: JSON.stringify(postData),
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.error("Error:", error));
};
callApi();
