import React, { useState, useEffect } from "react";
import { Select } from "antd";
import axios from "axios";

const { Option } = Select;

const Search = ({ setOfferData }) => {
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setOfferData(false);
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_OFFERS_API,
          {
            country: "all",
            type: "me",
            page: "1",
            limit: "10",
            pagination_state: "close",
          },
          {
            headers: {
              "x-api-key": "hawash",
            },
          }
        );
        setData(response.data.topics);
        setFilteredData(response.data.topics);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, []); // Fetch data only once on component mount

  const handleSearch = (value) => {
    setQuery(value);
    const filtered = data.filter(
      (item) =>
        item.translated_title
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase()) ||
        item.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    if (value !== "") setOfferData(JSON.parse(value));
    else {
      setOfferData(false);
    }
  };

  return (
    <Select
      dir={"rtl"}
      showSearch
      placeholder="اختر العرض"
      optionFilterProp="children"
      value={selectedValue}
      onChange={handleSelect}
      onSearch={handleSearch}
      filterOption={false} // Disabling filterOption to display all options
      loading={loading} // Show loading indicator while fetching data
    >
      <Option dir={"rtl"} value={""} key={-1}>
        اختر عرض
      </Option>
      {filteredData.map((item) => (
        <Option dir={"rtl"} key={item.id} value={JSON.stringify(item)}>
          {item.title} 
        </Option>
      ))}
    </Select>
  );
};

export default Search;
