import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
auth.useDeviceLanguage();

const handelSignOut = (setIsCorrectCode) => {
  const MySwal = withReactContent(Swal);
  signOut(auth)
    .then(() => {
      MySwal.fire(
        <div>
          <h1 style={{ color: "red" }}>تم تسجيل الخروج</h1>
        </div>
      );
      setIsCorrectCode(false);
      // Sign-out successful.
      console.log("User signed out");
    })
    .catch((error) => {
      // An error occurred during sign-out.
      console.error("Error signing out:", error);
    });
};

export { firestore, storage, auth, handelSignOut };
