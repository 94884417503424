import axios from "axios";

const sendNotificationCall = async (data) => {
  const url = "https://api.tsawq.me/send-notification";
  const apiKey = "hawash";

  const headers = {
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data.message;
  } catch (error) {
    return false;
  }
};

export default sendNotificationCall;
