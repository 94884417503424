import React, { memo, useEffect, useState } from "react";
import { Form, Row, Select } from "antd";
import { Button } from "react-bootstrap";
import { ButtonSection, StyledForm } from "../Style/styles";
import GetDataFromeFirestore, {
  deleteDocument,
} from "../API-Call/GetDataFromFirestore";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const RemovePromocode = memo((props) => {
  const MySwal = withReactContent(Swal);
  const [form] = Form.useForm();
  const { setDeleteButton } = props;
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [image_en, setImage_en] = useState("");
  const [image_ar, setImage_ar] = useState("");

  useEffect(() => {
    GetDataFromeFirestore(setData);
  }, [update]);

  return (
    <StyledForm form={form} layout="vertical">
      <Form style={{ direction: "rtl" }} form={form} layout="vertical">
        <Form.Item
          label={
            <div>
              <h3>اختر الأسم المراد حذفه</h3>
            </div>
          }
          name="Country"
          rules={[{ required: true, message: "Please select Country!" }]}
        >
          <Select
            placeholder="اختر الأسم"
            onChange={(val) => {
              try {
                const parsedVal = JSON.parse(val);
                setId(parsedVal.id);
                setImage_en(parsedVal.data.promocodes.image_en);
                setImage_ar(parsedVal.data.promocodes.image_ar);
                console.log(parsedVal.data.promocodes.image_en);
              } catch (error) {
                MySwal.fire({
                  title: (
                    <div>
                      <h3 style={{ color: "red" }}>حدث خطاء</h3>
                      <p>حاول مره اخرى</p>
                    </div>
                  ),
                  didOpen: () => {
                    MySwal.showLoading();
                  },
                });
                setTimeout(() => {
                  MySwal.close();
                }, 2000);
                return;
              }
            }}
          >
            {data.map((item) => (
              <Select.Option
                style={{ fontSize: 17, direction: "rtl" }}
                key={item.id}
                value={JSON.stringify(item)}
              >
                {item.data.promocodes.nameAR}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-around",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          {image_en.includes("http") && (
            <Form.Item>
              <img src={image_en} alt="Uploaded" width="200" />
            </Form.Item>
          )}
          <view style={{ width: 30 }} />
          {image_ar.includes("http") && (
            <Form.Item>
              <img src={image_ar} alt="Uploaded" width="200" />
            </Form.Item>
          )}
        </Row>
      </Form>
      <ButtonSection>
        <Button
          onClick={() => {
            form.resetFields();
            deleteDocument(id, setUpdate, image_en, setImage_en, update);
            deleteDocument(id, setUpdate, image_ar, setImage_ar, update);
            //assaa
          }}
          variant="success"
        >
          حذف
        </Button>
        <Button
          onClick={() => {
            setDeleteButton(false);
          }}
          variant="success"
        >
          العودة
        </Button>
      </ButtonSection>
    </StyledForm>
  );
});

export default RemovePromocode;

/* <Row>
  <Col>
    <StyledButton
      style={{ fontSize: 18, fontWeight: "bold" }}
      onClick={() => {
        form.resetFields();
        deleteDocument(id, setUpdate, update);
      }}
      type="primary"
    >
      حذف
    </StyledButton>
  </Col>
  <Col>
    <StyledButton
      style={{ fontSize: 18, fontWeight: "bold" }}
      onClick={() => {
        setDeleteButton(false);
      }}
      type="primary"
    >
      العودة
    </StyledButton>
  </Col>
</Row>; */
